
import About from './About'
import Mybooks from './Mybooks'


const Home = ({books}) => {



  
  return (
    <div className='overflow-hidden'>
        <About />
        <Mybooks  />
        
        
    </div>
    )
}

export default Home